import React, { useState,useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { PieChart, Pie, Sector, Cell } from "recharts";
import { ScrollPanel } from 'primereact/scrollpanel';
import { GoDotFill } from "react-icons/go";
import { BsFolder } from "react-icons/bs";
import { FaArrowTrendUp, FaSpinner } from "react-icons/fa6";
import { PiBatteryChargingLight, PiListChecks, PiUsersThree } from "react-icons/pi";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import RecommendTasks from "./RecommendTasks";
import TitleBar from "../../Common/TitleBar";
import SidePanel from "../../Common/SidePanel";
import DashboardHeader from "../../Common/DashboardHeader";
import { useDispatch } from "react-redux";
import { fetchTakerDashboardAction } from "../../../Redux/Actions/TaskerDashboardAction";
import { useNavigate } from "react-router-dom";

function Dashboard() {
const navigation=useNavigate()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dispatch=useDispatch()
    const[loader,setLoader]=useState(true)
    const[fetchData,setFetchData]=useState([])
    // piechart..........
    
    const data = [
        { name: "Group A", value: fetchData?.task_status?.inprogress },
        { name: "Group B", value: fetchData?.task_status?.approval_pending },
        { name: "Group c", value: fetchData?.task_status?.competed }
    ];
    const COLORS = ["#BAEDBD", "#95A4FC", "#1C1C1C"];

    // BarChart.......
    const data1 = [
        {
            name: 'Jan',
            uv: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: 'Feb',
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: 'Mar',
            uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: 'Apr',
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'May',
            uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'Jun',
            uv: 2390,
            pv: 3800,
            amt: 2500,
        },
        {
            name: 'Jul',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
        {
            name: 'Aug',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
        {
            name: 'Sep',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
        {
            name: 'Oct',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
        {
            name: 'Nov',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
        {
            name: 'Dec',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];

const fetchDataDashbord=()=>{
    dispatch(fetchTakerDashboardAction(setFetchData,setLoader))
}
useEffect(()=>{
fetchDataDashbord()
},[])


    return (
        <>
            <TitleBar title="Dashboard" />
            <div className="dashboard container-fluid overflow-hidden">
                <div className="row">
                    <div className="col-lg-2 col-md-3 col-sm-4 col-12">
                        <SidePanel />
                    </div>
                    <div className="col-lg-10 col-md-9 col-sm-8 col-12 p-0">
                        <div>
                            <div className="row">
                                <div className="col-xxl-10 col-xl-9 col-lg-8 ps-0 pe-0">
                                    <div className="dashboard-in">
                                        <DashboardHeader />

{
    loader?<div className="d-flex align-items-center justify-content-center mt-4 " style={{height:"100vh"}}><FaSpinner className="spin ms-1" style={{fontSize:"40px"}}/></div>:

                                        <ScrollPanel className="custombar2 content-area">
                                            <div className="p-4">
                                                <div className="do_task_wrapper ">
                                                    <div className="row">
                                                        <div className="col-xxl-3 col-lg-4 col-md-6 col-12 mt-xxl-0 mt-3 ">
                                                            <div className="total_task">
                                                                <div className="d-flex justify-content-between">
                                                                    <h3>Total Tasks</h3>
                                                                    <BsFolder className="m_icon" />
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <span className="task_no" style={{ fontSize: "24px" }}>{fetchData.totaltask}</span>
                                                                    {/* <div className="class_trend">
                                                                        <span className="me-1">+11.01%</span>
                                                                        <FaArrowTrendUp />
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-3 col-lg-4 col-md-6 col-12 mt-xxl-0 mt-3 ">
                                                            <div className="total_task d2">
                                                                <div className="d-flex justify-content-between">
                                                                    <h3>Ongoing Tasks</h3>
                                                                    <PiListChecks className="m_icon" />
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <span className="task_no" style={{ fontSize: "24px" }}>{fetchData.ongoing_task}</span>
                                                                    {/* <div className="class_trend">
                                                                        <span className="me-1">+11.01%</span>
                                                                        <FaArrowTrendUp />
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-3 col-lg-4 col-md-6 col-12 mt-xxl-0 mt-3 ">
                                                            <div className="total_task">
                                                                <div className="d-flex justify-content-between">
                                                                    <h3>Approval Pending</h3>
                                                                    <PiUsersThree className="m_icon" />
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <span className="task_no" style={{ fontSize: "24px" }}>{fetchData.approve_pending}</span>
                                                                    {/* <div className="class_trend">
                                                                        <span className="me-1">+11.01%</span>
                                                                        <FaArrowTrendUp className="down_trend" />
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-3 col-lg-4 col-md-6 col-12 mt-xxl-0 mt-3 ">
                                                            <div className="total_task d2">
                                                                <div className="d-flex justify-content-between">
                                                                    <h3>My Earnings</h3>
                                                                    <PiBatteryChargingLight className="m_icon" />
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <span className="task_no" style={{ fontSize: "24px" }}>₹{fetchData.myearning}</span>
                                                                    {/* <div className="class_trend">
                                                                        <span className="me-1">+11.01%</span>
                                                                        <FaArrowTrendUp />
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xxl-3 col-xl-4 col-12">
                                                        <div className="campaign-stat-left w-100">
                                                            <div className="account-block status">
                                                                <h6>Task Status</h6>
                                                                <div>
                                                                    <div className="dash_graph" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "400px", width: "100%" }}>
                                                                        <ResponsiveContainer width="100%" height="100%">
                                                                            <PieChart>
                                                                                <Pie
                                                                                    data={data}
                                                                                    cx="50%"
                                                                                    cy="50%"
                                                                                    innerRadius={60}
                                                                                    outerRadius={80}
                                                                                    fill="#8884d8"
                                                                                    paddingAngle={5}
                                                                                    dataKey="value"
                                                                                >
                                                                                    {data.map((entry, index) => (
                                                                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                                    ))}
                                                                                </Pie>
                                                                            </PieChart>
                                                                        </ResponsiveContainer>
                                                                    </div>
                                                                    <div style={{ marginTop: "-140px" }}>
                                                                        <div className="task_status">
                                                                            <p><GoDotFill style={{ color: "#1C1C1C", marginRight: "3px" }} />Competed</p>
                                                                            <span>{fetchData?.task_status?.competed}%</span>
                                                                        </div>
                                                                        <div className="task_status">
                                                                            <p><GoDotFill style={{ color: "#BAEDBD", marginRight: "3px" }} />In Progress</p>
                                                                            <span>{fetchData?.task_status?.inprogress}%</span>
                                                                        </div>
                                                                        <div className="task_status">
                                                                            <p><GoDotFill style={{ color: "#95A4FC", marginRight: "3px" }} />Approval Pending</p>
                                                                            <span>{fetchData?.task_status?.approval_pending}%</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-9 col-xl-8 col-12">
                                                        <div className="campaign-stat-right w-100 ps-0 pe-0">
                                                            <div className="account-block Task">
                                                                <div className="d-flex justify-content-between">
                                                                    <h6>Latest Tasks</h6>
                                                                    <a href="/tasks"><span>Show All</span></a>
                                                                </div>
                                                                <div className="table-responsive mt-3">
                                                                    <table className="table theme-table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Title</th>
                                                                                <th>Price</th>
                                                                                <th>Due Date</th>
                                                                                <th>Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                    {fetchData?.avaiable_task.length>0?fetchData?.avaiable_task?.slice(0,10).map((curElem,i)=>{
                                                                        return(
                                                                            <tr key={i}>
                                                                            <td data-label="Title">{curElem.title}</td>
                                                                            <td data-label="Price">{curElem.price}</td>
                                                                            <td data-label="Due Date">{curElem.due_date}</td>
                                                                             <td data-label="Action" onClick={() => navigation("/task-details", { state: { "slug": curElem?.slug } })}><p className="start_task">Start Task</p></td>
                                                                        </tr>
                                                                        )
                                                                    }):<div className="d-flex justify-content-center align-items-center">No task found</div>

                                                                    }       
                                                                            {/* <tr>
                                                                                <td data-label="Title">Follow DoTasks on Instagram</td>
                                                                                <td data-label="Price">$25</td>
                                                                                <td data-label="Due Date">2024-06-30</td>
                                                                                <td data-label="Status"><p className="Status_complete"><GoDotFill className="Status_complete" style={{ marginRight: "3px" }} />Complete</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td data-label="Title">Leave a Comment on Facebook Reel</td>
                                                                                <td data-label="Price">$35</td>
                                                                                <td data-label="Due Date">2024-06-30</td>
                                                                                <td data-label="Status"><p className="Status_Pending"><GoDotFill className="Status_Pending" style={{ marginRight: "3px" }} />Pending</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td data-label="Title">Post a Tweet on Twitter</td>
                                                                                <td data-label="Price">$45</td>
                                                                                <td data-label="Due Date">2024-06-30</td>
                                                                                <td data-label="Status"><p className="Status_Approved"><GoDotFill className="Status_Approved" style={{ marginRight: "3px" }} />Approved</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td data-label="Title">Upload Reel on Your Instagram</td>
                                                                                <td data-label="Price">$65</td>
                                                                                <td data-label="Due Date">2024-06-30</td>
                                                                                <td data-label="Status"><p className="Status_Rejected"><GoDotFill className="Status_Rejected" style={{ marginRight: "3px" }} />Rejected</p></td>
                                                                            </tr> */}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="account-block mt-4">
                                                    <h6>Tasks Overview</h6>


                                                    <div className="task_overview">
                                                        <ResponsiveContainer width="100%" height={300}>
                                                            <BarChart
                                                                data={data1}
                                                                margin={{
                                                                    top: 5,
                                                                    right: 30,
                                                                    left: 20,
                                                                    bottom: 5,
                                                                }}
                                                                barSize={20}
                                                            >
                                                                <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
                                                                <YAxis />
                                                                <Tooltip />
                                                                <Legend />
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <Bar dataKey="pv" fill="#A8C5DA" background={{ fill: "#eee" }} />
                                                            </BarChart>
                                                        </ResponsiveContainer>
                                                    </div>
                                                </div>
                                            </div>



                                        </ScrollPanel>
}
                                    </div>
                                </div>
                                <div className="col-xxl-2 col-xl-3 col-lg-4 ps-0">

                                    <RecommendTasks />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





        </>
    )
}

export default Dashboard;